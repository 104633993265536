<template>
  <v-layout column>
    <span class="grey--text text--darken-2 headline">{{ $t('guests.package_purchases') }}</span>
    <order-list
      :listId="table"
      :list="orders.list"
      :refreshColumnDecoration="refreshColumnDecoration"
      @refund="list(); $emit('change')"
      class="mt-2"
    />
  </v-layout>
</template>

<script>
import paginationMixins from '@/mixins/Pagination'
import permissionMixins from '@/mixins/Permission'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      table: 'GuestOrders',
      refreshColumnDecoration: null
    }
  },
  computed: {
    ...mapState(['guests', 'pagination', 'orders'])
  },
  methods: {
    list () {
      this.$store.commit('pagination/setLoading', { table: this.table, loading: true })
      this.$store.dispatch('orders/list', {
        limit: this.tablePagination.pages.itemsPerPage,
        offset: (this.tablePagination.pages.page - 1) * this.tablePagination.pages.itemsPerPage,
        accountIds: [this.guests.read.accountId],
        order: this.tablePagination.pages.descending ? 'ASC' : 'DESC',
        save: true
      }).then((response) => {
        this.updatePagination(response)
        this.refreshColumnDecoration = new Date()
        if (this.isPermissionActive('GET_ORDER_TRANSACTIONS')) {
          this.$store.dispatch('orders/decorateRefunded')
        }
      })
    }
  },
  created () {
    this.setItemsPerPageOptions({ value: [10, 25, 50], table: this.table })
    if (this.guests.read) {
      this.list()
    }
  },
  watch: {
    'guests.read' () {
      this.list()
    }
  },
  components: {
    OrderList: () => import('@/components/view-components/OrderList.vue')
  },
  mixins: [
    paginationMixins,
    permissionMixins
  ],
  name: 'GuestOrders',
  beforeDestroy () {
    this.$store.commit('orders/resetList')
  }
}
</script>
